<template>
  <div class="container-fluid">
    <footer class="text-center text-lg-start bg-danger text-white">
      <div class="container text-center text-md-start mt-5 pt-4">
        <div class="row mt-3">
          <div class="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
            <h6 class="fw-bold mb-4"><i class="fas fa-gem me-3"></i>AdWolt</h6>
            <p>Leading dealer and distributor.</p>
            <div class="me-5 d-none d-lg-block">
              <span>Get connected with us on social networks:</span>
            </div>
            <div>
              <a href="" class="me-4 text-reset">
                <i class="fab fa-facebook-f"></i>
              </a>
              <a href="" class="me-4 text-reset">
                <i class="fab fa-google"></i>
              </a>
              <a href="" class="me-4 text-reset">
                <i class="fab fa-instagram"></i>
              </a>
            </div>
          </div>
          <div
            class="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4 d-none d-lg-block"
          >
            <h6 class="text-uppercase fw-bold mb-4">Products</h6>
            <p>
              <a href="#!" class="text-reset">Adwolt wires</a>
            </p>
            <p>
              <a href="#!" class="text-reset">Polycab wires</a>
            </p>
            <p>
              <a href="#!" class="text-reset">KEI wires</a>
            </p>
            <p>
              <a href="#!" class="text-reset">some other product</a>
            </p>
          </div>
          <div
            class="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4 d-none d-lg-block"
          >
            <h6 class="text-uppercase fw-bold mb-4">Useful links</h6>
            <p>
              <a href="/" class="text-reset">Home</a>
            </p>
            <p>
              <a href="#/pricing" class="text-reset">Pricing</a>
            </p>
            <p>
              <a href="#/about" class="text-reset">About</a>
            </p>
            <p>
              <a href="#/contact-us" class="text-reset">ContactUs</a>
            </p>
          </div>
          <div class="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
            <h6 class="text-uppercase fw-bold mb-4">Contact</h6>
            <p>
              <i class="fas fa-home me-3"></i>B-2A, East Mohan Nagar, Amritsar
            </p>
            <p>
              <i class="fas fa-envelope me-3"></i>
              angaddistributors@gmail.com
            </p>
            <p><i class="fas fa-phone me-3"></i>+91 99888 79333</p>
            <p><i class="fas fa-print me-3"></i>+91 99889 79333</p>
          </div>
        </div>
      </div>
    </footer>
    <!-- Footer -->
  </div>
</template>
<script>
export default {
  name: "FooterComponent",
  data() {
    return {};
  },
};
</script>
