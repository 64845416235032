<template>
  <about-component />
</template>
<script>
import AboutComponent from "@/components/about/AboutComponent.vue";

export default {
  name: "AboutView",
  components: {
    AboutComponent,
  },
};
</script>
