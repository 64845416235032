<template>
  <div class="container col-xxl-8">
    <div class="row flex-lg-row-reverse align-items-center g-5 py-5">
      <div class="col-10 col-sm-8 col-lg-6">
        <img
          src="../../assets/about-us-photo-red.webp"
          class="d-block mx-lg-auto img-fluid"
          alt="Bootstrap Themes"
          width="700"
          height="500"
          loading="lazy"
        />
      </div>
      <div class="col-lg-6">
        <h1 class="display-5 fw-bold lh-1 mb-3">
          Leading distributor and manufacturer for your wiring needs
        </h1>
        <p class="lead">
          Adwolt is the manufacturer of the highest quality PVC Wires and
          Cables. Besides high quality and durability, our products are much
          more cost-effective and we provide you superior Fire resistant wires
          for extra safety. Our Eco friendly wires saves electricity and cuts
          down on long tern expenditure.
        </p>
        <div class="d-grid gap-2 d-md-flex justify-content-md-start">
          <a href="/" class="btn btn-danger btn-lg px-4 me-md-2"
            >Our Products</a
          >
        </div>
      </div>
    </div>

    <div class="px-4 pt-5 my-5 text-center border-bottom">
      <h1 class="display-4 fw-bold">Trusted distributor of</h1>
      <div class="row align-items-center">
        <div class="col-lg-4">
          <img
            src="../../assets/kei-logo.jpeg"
            class="img-fluid border rounded-3 shadow-lg mb-4"
            alt="Example image"
            loading="lazy"
          />
        </div>
        <div class="col-lg-4">
          <img
            src="../../assets/polycab-logo.png"
            class="img-fluid border rounded-3 shadow-lg mb-4"
            alt="Example image"
            loading="lazy"
          />
        </div>
        <div class="col-lg-4">
          <img
            src="../../assets/goldmedal-logo.svg"
            class="img-fluid border rounded-3 shadow-lg mb-4"
            alt="Example image"
            loading="lazy"
          />
        </div>
      </div>
    </div>
  </div>
</template>
