<template>
  <div class="row mt-5">
    <div class="col-md-12">
      <h1 class="text-danger fw-bold">Products</h1>
      <div class="row row-cols-1 row-cols-md-3 g-4 mt-4">
        <div class="col">
          <div class="card h-100">
            <img
              src="../../assets/adwolt-housing-wires.jpeg"
              class="card-img-top img-thumbnail p-4"
              alt="../../assets/adwolt-housing-wires.jpeg"
            />
            <div class="card-body">
              <h5 class="card-title">House wires</h5>
              <p class="card-text">
                Adwolt offers house wires for domestic and commercial purposes.
                These wires are safe, efficient to use.
              </p>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card h-100">
            <img
              src="../../assets/adwolt-submersible-cables.png"
              class="card-img-top img-thumbnail p-4"
              alt=""
            />
            <div class="card-body">
              <h5 class="card-title">Submersible wires</h5>
              <p class="card-text">
                Provides flat submersible cables mainly designed to use in
                submersible pumps in a deep well.
              </p>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card h-100">
            <img
              src="../../assets/adwolt-power-cable.jpeg"
              class="card-img-top img-thumbnail p-4"
              alt=""
            />
            <div class="card-body">
              <h5 class="card-title">Multicore wires</h5>
              <p class="card-text">
                Multicore wires by Adwolt is a perfect solution to use in
                houses, workspace or in commercial place.
              </p>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card h-100">
            <img
              src="../../assets/adwolt-coaxial-cable-large.jpeg"
              class="card-img-top img-thumbnail p-4"
              alt=""
            />
            <div class="card-body">
              <h5 class="card-title">Coaxial wires</h5>
              <p class="card-text">
                Coaxial cables are copper-based wires to transmit data, video,
                and voice communications to end-use.
              </p>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card h-100">
            <img
              src="../../assets/adwolt-service-wire.jpeg"
              class="card-img-top img-thumbnail p-4"
              alt=""
            />
            <div class="card-body">
              <h5 class="card-title">Service wires</h5>
              <p class="card-text">
                We provide service wires which are in huge demand and safer to
                use for household purposes.
              </p>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card h-100">
            <img
              src="../../assets/LAN-cable.jpeg"
              class="card-img-top img-thumbnail p-4"
              alt=""
            />
            <div class="card-body">
              <h5 class="card-title">LAN cable</h5>
              <p class="card-text">
                Highely demanded by users due to better LAN cable quality, fire
                retardant, safe and at reasonable price.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>

<style scoped>
div.card > img {
  max-height: 250px !important;
}
</style>
